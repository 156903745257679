.button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    background-color: var(--color-primary);
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-weight: bold;
    height: 4.8rem;
    width: 100%;
    justify-content: center;
    outline: none;

    &.-normal {
        background-color: #ffffff;
        color: var(--color-primary);
    }
}
