.spinner {
    display:inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 3px solid #ffffff;
    border-bottom-color: transparent;
    animation: spinner-anim .7s linear infinite;
}

@keyframes spinner-anim {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
