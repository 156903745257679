.bottom-sheet {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    overflow: hidden;

    > .background {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: #000000;
        opacity: 0.3;

        z-index: 1;
    }

    > .content {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        padding: 1.6rem;
        max-height: 100%;
        overflow-y: auto;

        background-color: #ffffff;
        opacity: 1;

        z-index: 2;
    }

    > .content > .close {
        position: absolute;
        right: 1.6rem;
        top: 1.6rem;
    }

    > .content > .inner > .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #000000;
        font-size: 2.4rem;
        font-weight: bold;
        margin-top: 4.8rem;
        margin-bottom: 1.6rem;
    }

    > .content > .inner > .title > .icon {
        font-size: 3.2rem;
    }

    > .content > .inner > .text {
        font-size: 1.6rem;
    }

    > .content > .inner > .button {
        margin-top: 3.2rem;
    }

    > .content > .inner > .buttons {
        margin-top: 3.2rem;

        > * {
            margin-bottom: 1.6rem;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    > .content > .inner > .option-list {
        display: flex;
        flex-direction: column;
    }

    > .content > .inner > .option-list > .option {
        margin-bottom: 8px;
        border-bottom: 1px solid #f7f7f7;
        background-color: #f7f7f7;
        -webkit-tap-highlight-color: transparent;

        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        font-size: 1.6rem;
        font-weight: bold;
        height: 4.8rem;
    }
}
