.node-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding-right: 52px;
    font-weight: bold;
    text-overflow: ellipsis;
    word-break: break-word;

    &.-title {
        font-size: 2rem;
        margin-bottom: 0.8rem;
    }

    &.-subtitle {
        font-size: 1.6rem;
    }

    &.-parameter {
        font-size: 1.6rem;
    }

    > .info {
        position: absolute;
        right: 8px;
        top: calc(50% - 22px);
        z-index: 1;
    }
}
