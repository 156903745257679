swegon-slider {
    width: 100%;
}

.swegon-slider {
    position: relative;

    width: calc(100% - 20px);
    height: 44px;
    margin-left: 10px;
    margin-right: 10px;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;

    cursor: default;
    user-select: none;
}

.swegon-slider__rail-left {
    position: absolute;
    top: calc(50% - 2px);

    width: 0%;
    height: 4px;

    background-color: var(--color-primary);
}

.swegon-slider__rail-right {
    position: absolute;
    top: calc(50% - 2px);

    width: 100%;
    height: 4px;

    background-color: #dddddd;
    opacity: 0.5;
}

.swegon-slider__thumb {
    position: relative;
    top: calc(50% - 9px);
    z-index: 1;

    width: 18px;
    height: 18px;
    border-radius: 9px;

    background-color: var(--color-primary);
    touch-action: none;

    cursor: pointer;
}

.swegon-slider__thumb:after {
    content: "";
    position: absolute;
    top: -11px;
    bottom: -11px;
    left: -11px;
    right: -11px;
}
