.progress-bar {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-x: hidden;

    > .line {
        position: absolute;
        opacity: 0.4;
        background: var(--color-warning);
        width: 150%;
        height: 100%;
    }

    > .subline {
        position: absolute;
        background: var(--color-warning);
        width: 100%;
        height: 100%;
    }

    > .subline.-inc {
        animation: progress-bar-increase-anim 1.5s infinite;
    }

    > .subline.-dec {
        animation: progress-bar-decrease-anim 1.5s 0.5s infinite;
    }

    @keyframes progress-bar-increase-anim {
        from { transform:translateX(-5%) scale(.05); }
        to { transform:translateX(130%) scale(1); }
    }

    @keyframes progress-bar-decrease-anim {
        from { transform:translateX(-80%) scale(.8); }
        to { transform:translateX(110%) scale(.1); }
    }
}
