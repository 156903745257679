.page-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;

    > .content {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
    }

    > .content > .title {
        font-size: 2.0rem;
        font-weight: bold;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-left: 4.8rem;
        padding-right: 4.8rem;
        max-width: 100%;
    }

    > .content > .subtitle {
        font-size: 1.6rem;
        font-weight: normal;
        margin-top: 0.4rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        padding-left: 4.8rem;
        padding-right: 4.8rem;
    }

    > .content > .back {
        position: absolute;
        left: 0.4rem;
        top: calc(50% - 2.2rem);
        display: flex;
        justify-content: center;
        vertical-align: middle;
        font-size: 1.6rem;
        align-items: center;
        height: 4.4rem;
        width: 4.4rem;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    }

    > .progress {
        position: absolute;
        bottom: -0.7rem;
        left: 0;

        width: 100%;
        height: 0.7rem;
        background-color: transparent;
        z-index: 1;
    }
}
