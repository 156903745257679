.node {
    margin-bottom: 8px;
    border-bottom: 1px solid #fff;
    background-color: #ffffff;
    -webkit-tap-highlight-color: transparent;

    &.-no-margin {
        margin-bottom: 0;
    }

    &.-text {
        background-color: transparent;
        border-color: transparent;
    }

    &.-link > .inner {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        font-size: 1.6rem;
        font-weight: bold;

        &.-normal {
            font-size: 1.6rem;
            height: 4.8rem;
        }

        &.-button {
            font-size: 1.6rem;
            background-color: var(--color-primary);
            color: #ffffff;
            font-weight: bold;
            height: 4.8rem;
            justify-content: center;
        }
    }

    &.-image > .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.6rem;

        > .img {
            margin: 0.8rem auto;
            max-width: 100%;
            width: max-content;
            object-fit: scale-down;
        }

        > .text {
            font-size: 1.6rem;
            margin-block-start: 0rem;
            margin-block-end: 0rem;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
        }
    }

    &.-text > .inner {
        padding: 1.6rem;

        > .text {
            font-size: 1.6rem;
            margin-block-start: 0rem;
            margin-block-end: 0rem;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
        }
    }

    &.-datetime > .inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        padding: 16px;
        background-color: #ffffff;

        &.-is-link {
            cursor: pointer;
        }

        & > .value {
            font-size: 1.6rem;
            font-weight: bold;
        }
    }
}
