@import './colors.scss';
@import './fonts.scss';
@import './components/bottom-sheet.scss';
@import './components/button.scss';
@import './components/icon-button.scss';
@import './components/icon.scss';
@import './components/node-title.scss';
@import './components/node.scss';
@import './components/page-header.scss';
@import './components/page-transition.scss';
@import './components/page.scss';
@import './components/parameter.scss';
@import './components/progress-bar.scss';
@import './components/spinner.scss';
@import './components/swegon-slider.scss';
@import './components/switch.scss';
@import './components/timer.scss';

.app {
    font-family: 'SwegonBrown', sans-serif;
    background-color: #f7f7f7;
    color: #000000;
    width: 100%;
    height: 100%;
    position: relative;
}

.app, .app * {
    box-sizing: border-box;
}
