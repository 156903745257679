.icon-button {
    width: 44px;
    height: 44px;

    background: transparent;
    border: none;
    outline: none;

    cursor: pointer;

    -webkit-tap-highlight-color: transparent;
}
