.parameter.-switch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.6rem;
    position: relative;


    > .header {
        display: block;
        margin-bottom: 0.4rem;
    }

    > .header > .text {
        font-size: 1.4rem;
        margin-top: 0.4rem;
        padding-right: 0.8rem;
    }
}

.parameter.-option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem;
    position: relative;

    > .select {
        font-size: 1.6rem;
        max-width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    > .value {
        color: #000000;
        font-size: 1.6rem;
        font-weight: bold;
        text-align: right;

        &.-modified {
            color: var(--color-warning);
        }

        &.-readonly {
            color: #d3d3d3;
        }
    }

    > .header {
        min-width: 50%;
    }

    > .header > .text {
        font-size: 1.4rem;
        margin-top: 0.4rem;
        padding-right: 0.8rem;
    }
}

.parameter.-multioption {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.8rem 1.6rem;
    position: relative;

    > .header > .text {
        font-size: 1.4rem;
        margin-top: 0.4rem;
    }

    > .options {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-items: flex-start;
        margin-top: 0.8rem;
        font-size: 1.6rem;
    }

    > .options > .option {
        margin-right: 8px;
        margin-bottom: 8px;
        padding: 8px 16px;
        transition: background-color 0.2s ease, color 0.2s ease;
        font-size: 1.6rem;
        background-color: #e1e1e1;
        color: #7e7e7e;

        &.-checked {
            background-color: var(--color-primary);
            color: #ffffff;
        }
    }

    > .options > .option:last-child {
        margin-left: 0;
    }

    > .options > .option > input {
        display: none;
    }
}

.parameter.-numeric {
    padding: 1.6rem;

    > .header {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.4rem;
    }

    > .header > .value {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-left: auto;
        font-size: 1.6rem;
        min-width: 7rem;
        text-align: right;
        color: #000000;
        font-weight: bold;

        &.-modified {
            color: var(--color-warning);
        }
    }

    > .header > .unit {
        margin-left: 0.4rem;
        font-size: 1.6rem;
    }

    > .sub-header {
        font-size: 1.4rem;
        margin-bottom: 0.4rem;
    }

    > .control {
        display: flex;
        flex-direction: row;
        margin-top: 1.6rem;
    }

    > .control > .button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background-color: #ffffff;
        height: 4.4rem;
        width: 4.4rem;
        min-height: 4.4rem;
        min-width: 4.4rem;
        outline: none;
        cursor: pointer;

        &:before {
            width: 44px;
            height: 44px;
            position: absolute;
            left: 0;
            right: 0;
        }

        &:disabled {
            opacity: 0.3;
            cursor: default;
        }
    }

    > .control > .button > .inside {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1.7rem;
        background-color: #f5f5f5;
        height: 3.4rem;
        width: 3.4rem;
        min-height: 3.4rem;
        min-width: 3.4rem;
    }

    > .readonlybar {
        height: 4px;
        background-color: #eeeeee;
    }

    > .readonlybar > .inside {
        height: 100%;
        background-color: var(--color-primary);
    }
}

.parameter.-timer {
    padding: 1.6rem;

    > .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 2.4rem;
        font-weight: bold;
        margin-bottom: 4.8rem;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    > .time {
        font-size: 3.2rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 4rem;
    }

    > .text {
        font-size: 1.6rem;
    }
}
