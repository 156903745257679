@font-face {
    font-family: SwegonBrown;
    src: url("../assets/fonts/SwegonBrownWeb-Regular.woff2") format("woff2");
}

@font-face {
    font-family: SwegonBrown;
    src: url("../assets/fonts/SwegonBrownWeb-Bold.woff2") format("woff2");
    font-weight: bold;
}

@font-face {
    font-family: SwegonBrown;
    src: url("../assets/fonts/SwegonBrownWeb-Light.woff2") format("woff2");
    font-weight: light;
}

@font-face {
    font-family: SwegonIconFont;
    src: url("../assets/fonts/SwegonIcon.woff") format("woff"),
        url("../assets/fonts/SwegonIcon.ttf") format("ttf"),
        url("../assets/fonts/SwegonIcon.eot") format("eot");
}
