.timer {
    padding: 16px;
    background-color: #ffffff;

    > .time {
        margin-bottom: 4px;
        font-size: 20px;
    }

    > .state {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 4px;
        font-size: 24px;
        font-weight: bold;
    }

    > .state > .priority {
        display: flex;
        flex-direction: row;
        margin-left: 4px;

        > * {
            margin-left: 2px;
        }

        > *:first-child {
            margin-left: 0;
        }

        > .circle {
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: var(--color-primary);
        }
    }

    > .weekdays {
        display: flex;
        flex-direction: row;
        overflow-x: auto;

        > * {
            margin-left: 2px;
        }

        > *:first-child {
            margin-left: 0;
        }
    }

    > .weekdays > .weekday {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        font-size: 16px;
        padding: 4px;
        color: #ffffff;

        &.-on {
            background-color: var(--color-primary);
        }

        &.-off {
            background-color: #989898;
            color: #c3c3c3;
        }

        &.-overlap {
            background-color: var(--color-warning);
        }
    }
}
