.switch {
    position: relative;
    display: inline-block;
    width: 5.2rem;
    min-width: 5.2rem;
    height: 2.4rem;

    > .checkbox {
        opacity: 0;
        width: 0;
        height: 0;
    }

    > .handle {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #dedede;
        transition: .4s;
        border-radius: 2rem;
    }

    > .handle:before {
        position: absolute;
        content: "";
        height: 2rem;
        width: 2rem;
        left: 0.4rem;
        bottom: 0.2rem;
        background-color: #f5f5f5;
        transition: .4s;
        border-radius: 50%;
    }

    .checkbox:checked + .handle {
        background-color: var(--color-primary);
    }

    .checkbox:checked + .handle:before {
        -webkit-transform: translateX(2.4rem);
        -ms-transform: translateX(2.4rem);
        transform: translateX(2.4rem);
    }

    .checkbox:disabled + .handle {
        opacity: 0.3;
        cursor: default;
    }
}
