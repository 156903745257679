.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(0);
    transition: transform 0.2s ease-in;
    overflow: hidden;

    > .content {
        display: flex;
        flex-direction: column;

        padding-top: 1.6rem;
        overflow-y: scroll;
        flex: 1;
    }

    > .footer {
        padding: 1.6rem;
    }
}
